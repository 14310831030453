:root {
  --amplify-primary-color: #4CEFDA;
  --amplify-primary-tint: #3f6060;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.VictoryContainer > svg {
  overflow: visible;
}

.ant-picker-dropdown{
  z-index: 9999;
}

.custom-range-picker .ant-picker-input input {
  font-size: 25px;
  text-align: center;
}

.ant-picker-range {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgb(154, 154, 154);
}


.ant-picker-outlined:hover, .ant-picker-outlined:focus-within{
  border-color: #4CEFDA;
  box-shadow: none;
}

.custom-range-picker .ant-picker-range {
  border-color: #4CEFDA !important;
  border: none;
  border-bottom: 1px solid #4CEFDA;
  border-radius: 0;
}

.ant-picker .ant-picker-input >input {
  min-width: 150px;
}

.ant-picker-active-bar {
  background: #3ab7a7 !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  background: #33a698;
  color: black;
  border-radius: 50%;
}

.amplify-authenticator__subtitle {
  display: none !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled):before {
  background: #acf8ec;
}