/* Fonts */
@font-face {
  font-family: 'BC Alphapipe L';
  src: url('https://db.onlinewebfonts.com/t/fbe20f6c42a52efdca315763a8cee03b.eot'); /* IE9*/
  src: url('https://db.onlinewebfonts.com/t/fbe20f6c42a52efdca315763a8cee03b.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://db.onlinewebfonts.com/t/fbe20f6c42a52efdca315763a8cee03b.woff2')
      format('woff2'),
    /* chrome firefox */
      url('https://db.onlinewebfonts.com/t/fbe20f6c42a52efdca315763a8cee03b.woff')
      format('woff'),
    /* chrome firefox */
      url('https://db.onlinewebfonts.com/t/fbe20f6c42a52efdca315763a8cee03b.ttf')
      format('truetype'),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
      url('https://db.onlinewebfonts.com/t/fbe20f6c42a52efdca315763a8cee03b.svg#BC Alphapipe L')
      format('svg'); /* iOS 4.1- */
}

/* Login */
amplify-authenticator {
  align-items: center;
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
}

amplify-container {
  background-color: var(--amplify-grey);
}

.simplebar-vertical .simplebar-scrollbar:before {
  background-color: rgba(255, 255, 255, 0.7);
}

.css-ygzok4-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled { 
  background-color: #919293 !important;
}

.css-h4y409-MuiList-root {
  overflow-x: hidden;
}

.ant-picker-clear {
  display: none;
}